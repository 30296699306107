<template>
    <main class="swcluster-main" id="swcluster-datasandbox">
        <!-- page-header -->
        <div class="page-header header-divider">
            <div class="header-component">
                <nav class="breadcrumb">
                    <ol class="breadcrumb-wrapper">
                        <li class="breadcrumb-item"><a href="javascript:">홈</a></li>
                        <li class="breadcrumb-item"><a href="javascript:">관리자모드</a></li>
                        <li class="breadcrumb-item">
                            <a href="javascript:">{{ divisions.title }}</a>
                        </li>
                    </ol>
                </nav>
                <h2 class="page-title">{{ divisions.title }}</h2>
                <div class="page-title-bottom"></div>
            </div>
        </div>
        <!-- page-body -->
        <div class="page-body page-component">
            <!-- page-contents -->
            <div class="page-contents">
                <div class="board-form-container">
                    <div>
                        <div class="form-fields">
                            <div class="form-field">
                                <label v-if="mno != 1" class="form-label">권한</label>
                                <select v-if="mno != 1" v-model.trim="params.memberTypeDcd" class="form-input">
                                    <option disabled value="">권한을 선택해주세요.</option>
                                    <option value="1001001">관리자</option>
                                    <option value="1001002">회원</option>
                                </select>
                            </div>
                            <div class="form-field">
                                <label class="form-label">사용여부</label>
                                <select v-model.trim="params.useYn" class="form-input">
                                    <option disabled value="">사용여부를 선택해주세요.</option>
                                    <option value="Y">사용</option>
                                    <option value="N">미사용</option>
                                </select>
                            </div>
                            <div class="form-field">
                                <label class="form-label">아이디</label>
                                <input v-if="mno != 1" v-model.trim="params.memberId" type="text" class="form-input"/>
                                <input v-else v-model.trim="params.memberId" type="text" class="form-input" readonly/>
                            </div>
                            <div class="form-field">
                                <label class="form-label">이름</label>
                                <input v-model.trim="params.memberNm" type="text" class="form-input" />
                            </div>
                            <div class="form-field">
                                <label v-if="mno > 0" class="form-label">비밀번호(변경시에만 입력)</label>
                                <label v-else class="form-label">비밀번호</label>
                                <input v-model.trim="params.memberPwd" type="password" class="form-input" />
                            </div>
                            <div class="form-field">
                                <label class="form-label">이메일</label>
                                <input v-model.trim="params.memberEmail" type="text" class="form-input" />
                            </div>
                            <!-- field:수행기간 입력 -->
                            <!--                            <div class="form-field">-->
                            <!--                                <label class="form-label">수행기간 입력</label>-->
                            <!--                                <input type="text" class="form-input" value="2021.03 - 2021.05" />-->
                            <!--                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
            <!-- //page-contents -->
        </div>
        <!-- //page-body -->
        <!-- page-bottom -->
        <div class="page-bottom bottom-fixed">
            <div>
                <div class="bottom-row-container bottom-component">
                    <div class="bottom-side bottom-side-fluid">
                        <div class="btn-actions">
                            <button v-if="mno > 0" class="btn-action btn-text" @click="deleteMemberData"><span class="text text-pink">삭제</span></button>
                        </div>
                        &nbsp;
                        <div class="btn-actions">
                            <button class="btn-action btn-text" @click="updateMemberData">
                                <span class="text">등록</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- //page-bottom -->
    </main>
</template>
<style></style>

<script>
import {computed, onMounted, onUnmounted, reactive, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {getItem, isSuccess, lengthCheck, setParams, timestampToDateFormat} from '@/assets/js/common.utils';
import ApiService from '@/assets/js/api.service';
import {setPageEditor} from '@/assets/js/editor/editor.utils';
import {hideLoading, showLoading} from '@/assets/js/common.alert';
import {getAuthSession} from '@/assets/js/modules/auth/module';
import {validateParams} from '@/assets/js/common.validate';

export default {
    name: 'MemberEdit',
    components: {},
    setup: function () {
        //showLoading();

        const route = useRoute();
        const router = useRouter();
        const pageEditor = ref(null);
        const session = computed(getAuthSession);

        const mno = computed(() => (route.params.mno ? parseInt(route.params.mno) : mno));

        const params = reactive({
            mno: '',
            memberId: '',
            memberNm: '',
            memberPwd: '',
            memberEmail: '',
            memberTypeDcd: '',
            useYn: '',
            //metaNm: '',
            //metaContents: '',
        });

        const divisions = reactive({
            title: computed(() => {
                if (route.name.includes('MemberEdit') && params.mno > 0) {
                    return '회원 정보 수정';
                } else if (route.name.includes('MemberEdit') && params.mno == 0) {
                    return '회원 정보 등록';
                }
                return '';
            }),
        });

        let tryCount = 0;
        let instance = null;

        const setEditorInstance = editor => {
            instance = editor;
            editor.setData(params.metaContents);
            hideLoading();
        };

        const getMemberData = () => {
            showLoading();
            if (mno.value > 0) {
                ApiService.get('/v1/members', mno.value)
                    .then(res => {
                        if (lengthCheck(res)) {
                            const item = getItem(res);
                            setParams(params, item);
                            setPageEditor(pageEditor.value, tryCount, setEditorInstance, '/v1/members/update');
                            hideLoading();
                        } else {
                            hideLoading();
                            router.push({name: 'Manager'});
                        }
                    })
                    .catch(e => {
                        hideLoading();
                        console.error(e);
                    });
            }
        };

        const validateKeys = {
            memberId: '아이디를 입력해주세요.',
            memberNm: '이름을 입력해주세요.',
            memberEmail: '이메일을 입력해주세요.',
            memberTypeDcd: '권한을 선택해주세요.',
            useYn: '사용여부를 선택해주세요.',
        };

        const updateMemberData = () => {
            //params.metaContents = instance ? instance.getData() : params.metaContents;
            if (validateParams(validateKeys, params, false)) {
                if (mno.value) {
                    if (confirm(`회원 정보를 수정하시겠습니까?`)) {
                        showLoading();
                        ApiService.update('/v1/members/Edit', params.mno, params)
                            .then(res => {
                                hideLoading();
                                if (isSuccess(res)) {
                                    alert(`회원 정보가 등록되었습니다.`);
                                    router.push({name: 'Manager'});
                                } else {
                                    alert(`오류가 발생했습니다. 잠시후 다시 시도해주세요.`);
                                }
                            })
                            .catch(e => {
                                console.error(e);
                                hideLoading();
                                alert(`오류가 발생했습니다. 잠시후 다시 시도해주세요.`);
                            });
                    }
                } else {
                    if (params.memberPwd) {
                        if (confirm(`회원 정보를 등록하시겠습니까?`)) {
                            ApiService.post('/v1/members', params)
                                .then(res => {
                                    hideLoading();
                                    if (isSuccess(res)) {
                                        alert(`회원 정보가 등록되었습니다.`);
                                        router.push({name: 'Manager'});
                                    } else {
                                        alert(`오류가 발생했습니다. 잠시후 다시 시도해주세요.`);
                                    }
                                })
                                .catch(e => {
                                    console.error(e);
                                    hideLoading();
                                    alert(`오류가 발생했습니다. 잠시후 다시 시도해주세요.`);
                                });
                        }
                    } else {
                        alert('비밀번호를 입력해주세요.');
                    }
                }
            }
        };

        const deleteMemberData = () => {
            if (mno.value) {
                if (confirm(`회원 정보를 삭제하시겠습니까?`)) {
                    showLoading();
                    ApiService.delete('/v1/members', params.mno)
                        .then(res => {
                            hideLoading();
                            if (isSuccess(res)) {
                                alert(`회원 정보가 삭제되었습니다.`);
                                router.push({name: 'Manager'});
                            } else {
                                alert(`오류가 발생했습니다. 잠시후 다시 시도해주세요.`);
                            }
                        })
                        .catch(e => {
                            console.error(e);
                            hideLoading();
                            alert(`오류가 발생했습니다. 잠시후 다시 시도해주세요.`);
                        });
                }
            } else {
                alert('잘못된 회원 정보입니다.');
                router.push({name: 'Manager'});
            }
        };
        /*
            if (validateParams(validateKeys, params, false)) {
                if (confirm(`회원 정보를 등록하시겠습니까?`)) {
                    showLoading();
                    if (mno.value) {
                        ApiService.update('/v1/members/Edit', params.mno, params)
                            .then(res => {
                                hideLoading();
                                if (isSuccess(res)) {
                                    alert(`회원 정보가 등록되었습니다.`);
                                } else {
                                    alert(`오류가 발생했습니다. 잠시후 다시 시도해주세요.`);
                                }
                            })
                            .catch(e => {
                                console.error(e);
                                hideLoading();
                                alert(`오류가 발생했습니다. 잠시후 다시 시도해주세요.`);
                            });
                    } else {
                        //showLoading();
                        ApiService.post('/v1/members', params)
                            .then(res => {
                                hideLoading();
                                if (isSuccess(res)) {
                                    alert(`회원 정보가 등록되었습니다.`);
                                } else {
                                    alert(`오류가 발생했습니다. 잠시후 다시 시도해주세요.`);
                                }
                            })
                            .catch(e => {
                                console.error(e);
                                hideLoading();
                                alert(`오류가 발생했습니다. 잠시후 다시 시도해주세요.`);
                            });
                    }
                }
            }*/

        onMounted(() => {
            if (session.value.manager) {
                if (mno.value > 0) {
                    //getAwsMeta();
                    getMemberData();
                } else {
                    hideLoading();
                }
            } else {
                hideLoading();
                router.push({name: 'Main'});
            }
        });

        onUnmounted(() => {
            if (instance !== null) {
                instance.destroy();
            }
        });

        return {
            mno,
            divisions,
            params,
            pageEditor,
            updateMemberData,
            deleteMemberData,
            timestampToDateFormat,
        };
    },
};
</script>
